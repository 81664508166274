/** @format */

import React, {ReactNode} from 'react';
import Link from 'next/link';
import Head from 'next/head';
import Footer from '../patterns/Footer';
import {FooterProps} from '../patterns/Footer/FooterProps';
import MainMenu from '../patterns/MainMenu';
import MainMenuProps from '../patterns/MainMenu/MainMenuProps';
import NavBar from '../patterns/NavBar';
import {Box, Container} from '@chakra-ui/react';
import type NavBarProps from '../patterns/NavBar/navBarProps';

type LayoutProps = {
	children?: ReactNode;
	title?: string;
	seo: any;
	preview?: any;
	navBar?: NavBarProps;
	mainMenu?: MainMenuProps;
	footer?: FooterProps;
	subdomain?: string;
	currentUrl?: string;
};

const Layout = ({
	preview,
	children,
	title = 'Harlaxton',
	seo,
	navBar,
	mainMenu,
	footer,
	subdomain = '',
	currentUrl,
}: LayoutProps) => (
	<>
		<Head>
			<title>{seo?.title || title}</title>
			<meta charSet='utf-8' />
			<meta name='viewport' content='initial-scale=1.0, width=device-width' />
			{seo?.description ? (
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				<meta name='description' content={seo.description || ''}></meta>
			) : null}
			{seo?.keywords ? (
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				<meta name='keywords' content={seo?.keywords || ''}></meta>
			) : null}
			{/* Slate tracking script */}
			{subdomain && subdomain === 'college' ? (
				<script async src='https://pursueharlaxton.evansville.edu/ping'>
					/**/
				</script>
			) : null}
			{/* Slate tracking script */}
		</Head>

		{navBar ? (
			<NavBar {...navBar} subdomain={subdomain} currentUrl={currentUrl} />
		) : null}

		{/* <Alert preview={preview} /> */}

		<Box position='relative' zIndex='1'>
			{children}
		</Box>
		{footer ? <Footer {...footer} /> : null}
	</>
);

export default Layout;

function Alert({preview}) {
	return (
		<Container>
			<div className='py-2 text-center text-sm'>
				{preview ? (
					<>
						This is a page preview.{' '}
						<Link
							href='/api/exit-preview'
							className='underline hover:text-cyan duration-200 transition-colors'>
							Click here
						</Link>
						to exit preview mode.
					</>
				) : null}
			</div>
		</Container>
	);
}
