/** @format */
/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, react/prop-types: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/restrict-template-expressions: 1
 *
 * @format
 */

import Container from '../../components/container';
import {
	Button,
	Box,
	Flex,
	HStack,
	Link,
	useDisclosure,
	IconButton,
	VStack,
} from '@chakra-ui/react';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	CloseIcon,
	HamburgerIcon,
} from '@chakra-ui/icons';
import type NavBarProps from './navBarProps';
import NextLink from 'next/link';
import {linkResolver} from '../../lib/linkResolver';
import LogoBlack from '../../public/logos/component--harlaxton-logo-bl';
import {colors} from '../../styles/js/colors';
import {fonts} from '../../styles/js/fonts';
import { fontSizes } from '../../styles/js/typography';
import { useRouter } from 'next/router';

export default function NavBar({
	globalMenu,
	parentSiteMenu,
	mainMenu,
	subdomain = '',
	currentUrl = '',
}: NavBarProps) {
	const { isOpen: mobileMenuOpen, onToggle: menuOnToggle } = useDisclosure();
	const {
		asPath,
		pathname
	} = useRouter();


	const bookButtonsContent = [
		{
			subdomain: '',
			label: 'book tickets',
			link: 'https://tickets.harlaxton.co.uk/',
			color: 'maroon',
		},
		{
			subdomain: 'harlaxton',
			label: 'book tickets',
			link: 'https://tickets.harlaxton.co.uk/',
			color: 'maroon',
		},
		{
			subdomain: 'college',
			link: 'https://pursueharlaxton.evansville.edu/apply/',
			label: 'apply',
			color: 'aqua',
		},
		{
			subdomain: 'garden',
			link: 'https://garden.harlaxton.co.uk/wg-support/donations',
			label: 'donate',
			color: 'pine',
		},
	];

	const bookButton = bookButtonsContent.find(
		item => item.subdomain === subdomain,
	);

	// Events colour override for Book Now button
	let evtBtnColour = null;

	if (
		asPath &&
		asPath.toString().includes('/events')
	) {
		evtBtnColour = 'gold';
	}
	//

	const GlobalLink = ({link, bookButton}) => {
		const urlParts = link.url.split('.');

		let backgroundColour = '#fff';
		let fgColour = '#000000';

		if (urlParts[0].includes(bookButton?.subdomain)) {
			backgroundColour = '#fff';
			fgColour = '#000000';
		} else {
			backgroundColour = colors.grey[700];
			fgColour = '#fff';
		}
		// Handle Events paths
		if (
			bookButton?.subdomain !== 'college' &&
			bookButton?.subdomain !== 'garden'
		) {
			if (
				currentUrl.includes('/events') &&
				link.label.toLowerCase().includes('events')
			) {
				backgroundColour = '#fff';
				fgColour = '#000000';
			} else {
				backgroundColour = colors.grey[700];
				fgColour = '#fff';
			}
		}

		return (
			<Link
				textDecoration={'none'}
				color={fgColour}
				backgroundColor={backgroundColour}
				px={4}
				py={2}
				_hover={{color: colors.white, textDecoration: 'underline'}}
				fontSize={16}
				as={NextLink}
				href={link.url ? linkResolver(link.url) : '#'}
				key={`parent-nav-link_${link.label}_${link.id}`}>
				{link.label}
			</Link>
		);
	};

	const GlobalNavBar = () => (
		<Box
			id='globalNav'
			as='menu'
			position='relative'
			my='0'
			bg={colors.grey[700]}
			fontFamily={fonts.copernicusGalaxyMedium}
			h='40px'
			px={{
				base: '16px',
				md: '2rem',
				lg: '4rem',
			}}
			py={{
				base: '8px',
				md: '8px',
			}}
			display={{
				base: 'none',
				lg: 'flex',
			}}>
			<Flex justifyContent={'space-between'} w={'100%'}>
				{/* Parent Site Nav */}
				<HStack gap={0}>
					{/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-call */}
					{parentSiteMenu?.menuItems.map((link: any, i: number) => (
						<GlobalLink link={link} bookButton={bookButton} key={link} />
					))}
				</HStack>
				{/* / Parent Site Nav */}

				{/* Global Nav */}
				<HStack mr={0} ml={'auto'} gap={'1.5rem'}>
					{/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-call */}
					{globalMenu?.menuItems.map((link: any, i: number) => (
						<Link
							textDecoration={'none'}
							color={'#fff'}
							_hover={{color: colors.white, textDecoration: 'underline'}}
							fontSize={16}
							as={NextLink}
							href={link.url ? linkResolver(link.url) : '#'}
							key={`global-nav-link_${link.label}_${link.id}`}>
							{link.label}
						</Link>
					))}
				</HStack>
				{/* / Global Nav */}
			</Flex>
		</Box>
	);

	const MobileMenu = () => {
		return (
			<Box
				top='0'
				zIndex={2}
				overflow={'scroll'}
				w={'100%'}
				h={'90vh'}
				pb={8}
				mt={6}
				display={{
					base: 'flex',
					md: 'flex',
					lg: 'none',
				}}>
				<VStack
					w={'100%'}
					h={'100%'}
					my={6}
					alignItems={'flex-start'}
					gap={0}
					borderTop={'1px solid #0000001c'}>
					{mainMenu?.menuItems.map((link: any, i: number) => (
						<Link
							py={3}
							borderBottom={'1px solid #0000001c'}
							w={'100%'}
							textDecoration={'none'}
							as={NextLink}
							color={colors.black}
							_hover={{color: colors.black, textDecoration: 'underline'}}
							href={link.url ? linkResolver(link.url) : '#'}
							key={`mobile-nav-mainmenu-link_${link.label}_${link.id}`}>
							{link.label}
						</Link>
					))}

					{parentSiteMenu?.menuItems.map((link: any, i: number) => (
						<Link
							py={3}
							borderBottom={'1px solid #0000001c'}
							w={'100%'}
							textDecoration={'none'}
							as={NextLink}
							color={colors.black}
							_hover={{color: colors.black, textDecoration: 'underline'}}
							href={link.url ? linkResolver(link.url) : '#'}
							key={`mobile-nav-parentsite-link_${link.label}_${link.id}`}>
							{link.label}
						</Link>
					))}

					{globalMenu?.menuItems.map((link: any, i: number) => (
						<Link
							py={3}
							borderBottom={'1px solid #0000001c'}
							w={'100%'}
							textDecoration={'none'}
							as={NextLink}
							color={colors.black}
							_hover={{color: colors.black, textDecoration: 'underline'}}
							href={link.url ? linkResolver(link.url) : '#'}
							key={`mobile-nav-global-link_${link.label}_${link.id}`}>
							{link.label}
						</Link>
					))}
				</VStack>
			</Box>
		);
	};

	return (
		<Box
			position='sticky'
			top='0'
			zIndex={2}
			overflow={'hidden'}
			boxShadow='0 2px 2px 0 #0000001a'>
			<GlobalNavBar />
			<Box
				id='mainNav'
				as='menu'
				position='relative'
				my='0'
				bg={'#fff'}
				fontFamily={fonts.copernicusGalaxyMedium}
				px={{
					base: '18px',
					md: '2rem',
					lg: '4rem',
				}}
				py={{
					base: '16px',
					md: '1.25rem',
				}}>
				<Flex justifyContent={'flex-start'}>
					{/* Branding */}
					<Box as='a' href={process.env.NEXT_PUBLIC_ROOT_DOMAIN}>
						<LogoBlack />
					</Box>
					{/* / Branding */}

					{/* Main Nav */}
					<HStack
						paddingLeft={10}
						pr={10}
						gap={'32px'}
						fontSize={{
							base: '10',
							lg: '16',
						}}
						display={{
							base: 'none',
							lg: 'flex',
						}}>
						{mainMenu?.menuItems.map((link: any, i: number) => (
							<Link
								textDecoration={'none'}
								as={NextLink}
								color={colors.black}
								_hover={{color: colors.black, textDecoration: 'underline'}}
								href={link.url ? linkResolver(link.url) : '#'}
								key={`main-nav-link_${link.label}_${link.id}`}>
								{link.label}
							</Link>
						))}
					</HStack>
					{/* / Main Nav */}

					{/* Right-hand menu section */}
					<Box marginLeft={'auto'} marginRight={0} alignItems={'center'} display={{
								base: 'flex',
								lg: 'flex',
							}}>
						<Button
							display={{
								base: 'flex',
								lg: 'flex',
							}}
							h={'48px'}
							colorScheme={evtBtnColour ? evtBtnColour : bookButton?.color}
							variant='solid'
							as={'a'}
							fontSize={16}
							href={bookButton?.link}>
							{bookButton?.label}
						</Button>

						{/* Mobile Menu Icon */}
						<IconButton
							display={{
								base: 'flex',
								md: 'flex',
								lg: 'none',
							}}
							ml={'0.75em'}
							colorScheme=''
							onClick={menuOnToggle}
							aria-label='Toggle menu'
							variant='unstyled'
							w={'auto'}
							icon={
								<>
									{mobileMenuOpen ? (
										<CloseIcon color={colors.black} boxSize={'0.8em'} />
									) : (
										<HamburgerIcon color={colors.black} boxSize={'1.2em'} />
									)}
								</>
							}></IconButton>
						{/* / Mobile Menu Icon */}
					</Box>
					{/* / Right-hand menu section */}
				</Flex>
				{mobileMenuOpen ? <MobileMenu /> : null}
			</Box>
		</Box>
	);
}
