/** @format */

import {
	Box,
	Button,
	Container,
	Flex,
	Grid,
	GridItem,
	Link,
	Text,
} from '@chakra-ui/react';
import {decode} from 'html-entities';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';
import Image from 'next/image';
import {DeepFooterProps, FooterProps, LogoContentProps} from './FooterProps';

const Footer: React.FC<FooterProps> = ({
	logoContainer,
	otherCols = [],
	deepFooterlinks,
}) => {
	const cols12 = 12;
	const maxCols = 4;
	// -1 because logo is 1 col
	let lgCols = Math.round(cols12 / otherCols.length - 1);
	if (otherCols.length > maxCols) {
		lgCols = Math.round(cols12 / maxCols);
	}

	const colSpan = {base: cols12, md: 6, lg: lgCols};

	return (
		<Box
			bg={colors.grey[800]}
			color={colors.white.whiteAlpha}
			as='section'
			overflow='hidden'
			position='relative'>
			<Container maxW='container.xl'>
				<Grid
					templateColumns={{
						base: '1fr',
						md: 'repeat(12, 1fr)',
					}}
					gap={{base: '3.5rem'}}
					py={{base: '3rem', md: '4rem'}}>
					<GridItem colSpan={colSpan}>
						<LogoContent logoContainer={logoContainer} />
					</GridItem>

					{otherCols.map((col, index) => {
						return (
							<GridItem key={`col-${index}`} colSpan={colSpan}>
								{col?.title && col?.links?.length > 0 ? (
									<>
										<Text
											{...textStyles.textxs}
											textTransform={'uppercase'}
											pb={{base: '1.5rem'}}>
											{col.title}
										</Text>

										<Box mt={2}>
											{col.links.map((link, i) => {
												return (
													<Box pb={{base: '1rem'}} key={`col-${i}`}>
														<Button
															className='footer-link'
															colorScheme={colors.white.whiteAlpha}
															as='a'
															href={link.url}
															target={link.target}
															variant='link'
															{...textStyles.textsm}>
															{decode(link.title)}
														</Button>
													</Box>
												);
											})}
										</Box>
									</>
								) : null}
							</GridItem>
						);
					})}
				</Grid>

				<DeepFooter deepFooterlinks={deepFooterlinks} />
			</Container>
		</Box>
	);
};
export default Footer;

const LogoContent: React.FC<LogoContentProps> = ({logoContainer}) => {
	return (
		<Grid>
			<Flex width='fit-content' flexDirection={'column'} alignItems={'center'}>
				{logoContainer?.image?.src ? (
					<Image
						src={logoContainer.image.src}
						alt={logoContainer?.logoText}
						width='64'
						height='80'
					/>
				) : null}
				<Text {...textStyles.textsm} my={{base: '2rem'}}>
					{logoContainer?.logoText}
				</Text>
			</Flex>

			{logoContainer?.addressLines.map((addressLineItem, index) => (
				<Text {...textStyles.textsm} key={`address-${index}`}>
					{addressLineItem}
				</Text>
			))}
		</Grid>
	);
};

const DeepFooter: React.FC<DeepFooterProps> = ({deepFooterlinks}) => {
	return (
		<Grid
			className='deep-footer'
			templateColumns={{base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)'}}
			gap={{base: '3rem', md: '3.5rem'}}
			py={{base: '2rem'}}
			borderTop={`1px solid ${colors.grey[600]}`}>
			{deepFooterlinks?.map(({title, url, target}, i) => {
				if (!title) return null;

				const decodedTitle = decode(title);

				if (i === 0 || url === '/')
					return (
						<Box flex={{base: 1}} key={decodedTitle}>
							<Text {...textStyles.textxs}>
								© {new Date().getFullYear()} {decodedTitle}
							</Text>
						</Box>
					);

				return (
					<Box flex={{base: 1}} key={decodedTitle}>
						<Button
							className='deep-footer-link'
							colorScheme={colors.white.whiteAlpha}
							as='a'
							href={url}
							target={target}
							variant='link'
							{...textStyles.textsm}>
							{decodedTitle}
						</Button>
					</Box>
				);
			})}
		</Grid>
	);
};
